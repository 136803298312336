<template>
<div>
    <div class="col-md-12">
        <div class="row" style="margin-top:20px">
            <div class="col-md-6">
                <h3 class="page-header">
                <i class="fa fa fa-user animated bounceInDown show-info"></i>
                  Refund Running Schemes
                </h3>
            </div>
            <div class="col-md-6 ">
                <div class="row" style="padding-top:18px;justify-content:flex-end;" >
                    <!-- <div class="col-md-2" style="margin-right:25px" v-if="viewno==2">   
                        <button @click="back()" class="btn btn-primary" ><i class="fa fa-backward"></i>  Back</button>
                    </div> 
                    <div v-if="loggedinuser!=null && loggedinuser.accessids.includes('25') && viewno==2" class="col-md-2" style="margin-right:25px;" >
                        <button @click="add()" class="btn btn-primary" ><i class="fa fa fa-user"></i>  Add New</button>  
                    </div> -->
                    <div class="col-md-2" style="margin-right:25px;">             
                        <button @click="refresh()" class="btn btn-refresh" ><i class="fa fa-refresh"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="panel">
            <div class="panel-body">
                <table class="table users-table table-condensed table-hover table-sm" id="tblmanual">
                    <thead class="table-font">
                        <tr>
                            <th>#</th>
                            <th>LAN No.</th>
                            <th>VSDIGI-ID</th>
                            <th>Customer</th>
                            <!-- <th>Scheme-ID</th> -->
                            <th>Scheme</th>
                            <th>Sale Date</th>
                            <th>Start Date</th>
                            <th>SP</th>
                            <th>EMI</th>
                            <th>Entry Date</th>
                            <th >Entered By</th>
                            <th style="width:0px">&nbsp;</th>
                            <th style="width:0px">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody class="table-font">
                       <tr v-for="(item,index) in refundschemes" v-bind:key="item.id">
                            <td>{{index+1 }}</td>
                            <td><a href="#">{{item.generatedid }}</a></td>
                            <td><span v-if="item.cutomer!=null">{{item.customer.generatedid}}</span></td>
                            <td><span v-if="item.cutomer!=null">{{item.customer.name}}</span></td>
                            <!-- <td>{{item.schemeid}}</td> -->
                            <td><span v-if="item.scheme!=null">{{item.scheme.name}}</span></td>
                            <td>{{moment(item.saledate).format('DD-MM-YYYY')}}</td>
                            <td>{{moment(item.startdate).format('DD-MM-YYYY')}}</td>
                            <td><span v-if="item.scheme!=null">{{item.scheme.sp}}</span></td>
                            <td><span v-if="item.scheme!=null">{{item.scheme.emi}}</span></td>
                            <td>{{ moment(item.created_at).format("DD-MM-YYYY")}}</td>
                            <td><div v-if="item.enteredby!=null">{{item.enteredby.name}}</div></td>
                            <td  style="padding:2px;padding-top:10px;">
                                <button type="button" class="btn btn-danger btn-xs" style="padding:0px;"  @click="updatedefault(item)"> Restore</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import Constants from '../../components/utilities/Constants.vue'
import moment from "moment";
export default {
    mixins:[Constants],
    data(){
        return{
            moment:moment
        }
    },
    computed:{
		...mapGetters([
		   'ongoingschemes','loggedinuser','refundschemes','schemes'
		]),
	},
    mounted(){
		this.refresh();
        this.$store.dispatch('fetchrefundschemes')
    },
    methods: {
        refresh(){
            this.$store.dispatch('fetchrefundschemes');
        },
        updatedefault(item){
             if(item.isrefund==1){
                 let parm = {column:'isrefund',id:item.id,value:0};
                this.$http.post('api/ongoingschemes/updatefield',parm)
                .then((response) => {
                    this.$store.commit('updateappendongoingschemes',response.data);
                })
                .catch((err) => {
                    console.log(err)
                });
            }
        },
        
    }
}
</script>
